<template>
  <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header card-header-fixed border-0 px-6">
            <div class="row align-items-center col-9 px-0">
                  <div class="col-5 pr-0">
                    <div class="input-icon">
                      <input 
                        type="text" 
                        class="form-control form-control-solid" 
                        :placeholder="$t('goodwemonitoring.placeholder.search')"
                        v-model="search"
                        >
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
            
                  <b-button
                    variant="secondary"
                    class="mr-3 ml-3 pr-2"
                     @click.prevent="removeFilters"
                  >
                    <i class="fa fa-times icon-md"></i>
                  </b-button>
                  <b-button
                    variant="primary"
                    @click.prevent="searchPlants"
                  >
                  {{ $t("button.filter") }}   
                  </b-button>

              </div>
              <div class="card-toolbar">
                <span class="btn btn-success">
                  <span class="svg-icon svg-icon-md svg-icon-white">
                    <inline-svg src="/media/svg/icons/General/Update.svg" />
                  </span>
                  {{ $t("goodwemonitoring.button.sync") }}
                </span>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::Card-->
    <div class="card card-custom" >
      <!--begin::Body-->
      <div class="card-body">
        <div class="table-responsive ">
          <table
            class="table table-head-custom table-head-bg table-vertical-center"
          >
            <thead>
              <tr class="text-left text-uppercase">
                <th width="40">{{ $t("goodwemonitoring.plants.label.action") }}</th>
                <th>{{ $t("goodwemonitoring.plants.label.plant_name") }}</th>
                <th>{{ $t("goodwemonitoring.plants.label.status") }}</th>
                <th>{{ $t("goodwemonitoring.plants.label.capacity") }}</th>
                <th>{{ $t("goodwemonitoring.plants.label.total_energy") }}</th>
                <th>{{ $t("goodwemonitoring.plants.label.plant_type") }}</th>
                <th width="80">{{ $t("goodwemonitoring.plants.label.devices") }}</th>
              </tr>
            </thead>
            <tbody v-if="!loading">

                <tr v-for="(plant, index) in plants.data"
                :key="index"
                :user="plant">

                  <td class="pl-0">
                    <router-link
                      :to="{ name: 'goodwemonitoring_plant_detail', params: { id: plant.plant_id }}"
                      class="btn btn-icon btn-light btn-hover-primary  btn-sm"
                      >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg src="/media/svg/icons/General/Search.svg" />
                      </span>
                    </router-link>
                  </td>

                  <td>
                    <span class="font-weight-bold">{{ plant.plant_name }}</span>
                  </td>

                  <td>
                    <b-badge v-if="plant.status == 1" variant="success">{{ $t("goodwemonitoring.label.generating") }}</b-badge>
                    <b-badge v-if="plant.status == -1" variant="danger">{{ $t("goodwemonitoring.label.offline") }}</b-badge>
                    <b-badge v-if="plant.status == 0" variant="warning">{{ $t("goodwemonitoring.label.waiting") }}</b-badge>
                    <b-badge v-if="plant.status == 2" variant="danger">{{ $t("goodwemonitoring.label.fault") }}</b-badge>
                    <b-badge v-if="plant.status == null" variant="secondary" class="px-7">-</b-badge>
                  </td>

                  <td>
                    <span v-if="plant.capacity"  class="text-dark-75 font-weight-bolder">{{ plant.capacity.toFixed(3) }} kW </span>
                     <span v-else class="text-muted">-</span>
                  </td>

                  <td>
                    <span class="" v-if="plant.total > 0">{{ plant.total.toFixed(1) }} kW </span>
                    <span v-else class="text-muted">-</span>
                  </td>

                  <td>
                    <span class="text-muted font-weight-bold">
                      <span v-if="plant.plant_type == 1"><small class="fas fa-battery-full text-success pr-1"></small> {{ $t("goodwemonitoring.plants.label.battery_storage") }}</span>
                      <span v-if="plant.plant_type == 2"><small class="fas fa-house-user text-success pr-1"></small> {{ $t("goodwemonitoring.plants.label.residental") }}</span>
                      <span v-if="plant.plant_type == 3"><small class="fab fa-houzz text-success pr-1"></small> {{ $t("goodwemonitoring.plants.label.commercial_rooftop") }}</span>
                      <span v-if="plant.plant_type == 4"><small class="fas fa-equals text-success pr-1"></small> {{ $t("goodwemonitoring.plants.label.ground_mounted") }}</span>
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="btn btn-sm btn btn-clean btn-light-primary btn-hover-light-primary btn-sm btn-icon font-weight-bold">{{ plant.devices_count }}</span>
                  </td>
                  
                </tr>
            </tbody>
          </table>
          <Pagination :data="plants.meta" v-if="!loading"></Pagination>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Pagination from "@/view/content/Pagination";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      loading: false,
      plants: null,
      page: 1,
      search: "",
    };
  },
  components: {
    Pagination
  },
  beforeMount() {
    // this.checkLastSearch();
    this.getPlants();
  },
  metaInfo () { 
      return { title: this.$t("goodwemonitoring.meta.plant_list")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("goodwemonitoring.meta.plants"), subtitle: this.$t("goodwemonitoring.meta.plant_list") }]);
  },
  computed: {
    perpage() {
      return this.$store.state.pagination.pagination.itemsPerPage;
    },
    plantSearch: {
      get () {
        return this.$store.state.search.plantSearch;
      },
      set (value) {
        this.$store.commit('setPlantSearch', value);
      }
    },
  },
  methods: {
    getPlants() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/goodwe-monitoring/plants?page=" + this.page + "&perpage=" + this.perpage + "&search=" + this.search)
        .then((response) => {
          this.plants = response.data;
          this.loading = false;
        });
    },
    onUpdatePage(newPage) {
      this.page = newPage;
      this.getPlants();
    },
    searchPlants(){
      this.page = 1;
      this.getPlants(); 
      this.plantSearch = {
        search: this.search
      } 
    },
    removeFilters(){
      this.page = 1;
      this.search = "";
      this.plantSearch = {
        search: this.search
      };
      this.getPlants(); 
    },
    checkLastSearch(){
      let lastSearch = this.$store.state.search.plantSearch;
      this.search = lastSearch.search;
    }
  }
};
</script>
